import { IImplementationEntityProposalResource, ImplementationEntityProposalResource } from '../proposal/ImplementationEntityProposalResource'
import { IImplementationProcessResource, ImplementationProcessResource } from './ImplementationProcessResource'

export interface IImplementationEntityProcessResource extends IImplementationProcessResource {
  proposals:	IImplementationEntityProposalResource[]
  stats: {
    proposals_count: number
    answered_count: number
  }
}

export const isImplementationEntityProcessResource = (item: ImplementationProcessResource): item is ImplementationEntityProcessResource => {
  return 'stats' in item
}

export class ImplementationEntityProcessResource extends ImplementationProcessResource {
  public proposals:	ImplementationEntityProposalResource[]
  public stats: {
    proposals_count: number
    answered_count: number
  }

  public entity_id: number

  constructor({ process, implementation_id, entity_id }: { process: IImplementationEntityProcessResource, implementation_id: number, entity_id: number }) {
    super({ process, implementation_id })
    this.stats = process.stats

    this.entity_id = entity_id
    this.proposals = process?.proposals.map((proposal) => new ImplementationEntityProposalResource({ proposal, implementation_id, entity_id })) ?? []
  }
}
