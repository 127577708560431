import { AxiosPromise } from 'axios'
import { BaseService }  from '@/services/base'

import { ProjectResource, IProjectResource }                       from '@/models/projects/ProjectResource'
import { NotificationResource, INotificationResource }             from '@/models/notifications/NotificationResource'

import { CommentResource, ICommentResource } from '@/models/comments/CommentResource'
import { IUserResource, UserResource } from '@/models/users/UserResource'
import { AttachUsersRequest } from '@/requests/implementations/AttachUsersRequest'
import { EntityResource, IEntityResource } from '@/models/implementations/entities/EntityResource'
import { EntityCollectionResource, IEntityCollectionResource } from '@/models/implementations/entities/EntityCollectionResource'
import { EntityCreateRequest } from '@/requests/implementations/entities/EntityCreateRequest'
import { QuestionCreateRequest } from '@/requests/implementations/questions/QuestionCreateRequest'
import { IQuestionResource, QuestionResource } from '@/models/implementations/questions/QuestionResource'
import { IImplementationResource, ImplementationResource } from '@/models/implementations/ImplementationResource'
import { QuestionPatchRequest } from '@/requests/implementations/questions/QuestionPatchRequest'
import { EntityUpdateRequest } from '@/requests/implementations/entities/EntityUpdateRequest'

export class ImplementationService extends BaseService {

  public implementation_id: number

  constructor({ implementation_id }: { implementation_id: number }) {
    super(`implementations/${implementation_id}`)
    this.implementation_id = implementation_id
  }

  public delete(): AxiosPromise<void> {
    return this.client.delete<void>(`${this.namespace}`)
  }

  public async getNotifications(namespace: 'activities' | 'notifications'): Promise<IndexResponse<NotificationResource>> {
    const { data } = await this.client.get<IndexResponse<INotificationResource>>(`${this.namespace}/${namespace}`)
    return {
      ...data,
      data: data.data.map((notification: INotificationResource) => new NotificationResource((notification)))
    }
  }

  public async readNotifications(namespace: 'activities' | 'notifications'): Promise<void> {
    await this.client.patch<IndexResponse<INotificationResource>>(`${this.namespace}/${namespace}/read`)
    return
  }


  public async getChat(params: IndexParameters): Promise<IndexResponse<CommentResource>> {
    const { data } = await this.client.get<IndexResponse<ICommentResource>>(`${this.namespace}/messages`, { params })
    return {
      ...data,
      data: data.data.map((comment: ICommentResource) => new CommentResource(comment))
    }
  }

  public async getAllUsers(): Promise<IndexResponse<UserResource>> {
    const { data } = await this.client.get<IndexResponse<IUserResource>>(`${this.namespace}/all-users`)
    return {
      ...data,
      data: data.data.map((user) => new UserResource(user))
    }
  }

  public async getUsers(): Promise<IndexResponse<UserResource>> {
    const { data } = await this.client.get<IndexResponse<IUserResource>>(`${this.namespace}/users`)
    return {
      ...data,
      data: data.data.map((user) => new UserResource(user))
    }
  }

  public async getAvailableUsers(): Promise<IndexResponse<UserResource>> {
    const { data } = await this.client.get<IndexResponse<IUserResource>>(`${this.namespace}/availableUsers`)
    return {
      ...data,
      data: data.data.map((user) => new UserResource(user))
    }
  }

  public async getAvailableEntityUsers(): Promise<IndexResponse<UserResource>> {
    const { data } = await this.client.get<IndexResponse<IUserResource>>(`${this.namespace}/entities/availableUsers`)
    return {
      ...data,
      data: data.data.map((user) => new UserResource(user))
    }
  }

  public async attachUsers(body: AttachUsersRequest): Promise<void> {
    await this.client.post<void>(`${this.namespace}/users`, body)
    return
  }

  public async deleteUser(userId: number): Promise<IndexResponse<UserResource>> {
    const { data } = await this.client.delete<IndexResponse<IUserResource>>(`${this.namespace}/users/${userId}`)
    return {
      ...data,
      data: data.data.map((user) => new UserResource(user))
    }
  }

  public async createEntity(body: EntityCreateRequest): Promise<DetailResponse<EntityResource>> {
    const { data } = await this.client.post<DetailResponse<IEntityResource>>(`${this.namespace}/entities`, body)
    return {
      ...data,
      data: new EntityResource(data.data, this.implementation_id)
    }
  }

  public async getEntities(params: IndexParameters): Promise<IndexResponse<EntityCollectionResource>> {
    const { data } = await this.client.get<IndexResponse<IEntityCollectionResource>>(`${this.namespace}/entities`, { params })
    return {
      ...data,
      data: data.data.map((entity) => new EntityCollectionResource(entity, this.implementation_id))
    }
  }

  public async getEntityById(id: number): Promise<DetailResponse<EntityResource>> {
    const { data } = await this.client.get<DetailResponse<IEntityResource>>(`${this.namespace}/entities/${id}`)
    return {
      ...data,
      data: new EntityResource(data.data, this.implementation_id)
    }
  }

  public async updateEntity(id: number, body: EntityUpdateRequest): Promise<DetailResponse<EntityResource>> {
    const { data } = await this.client.patch<DetailResponse<IEntityResource>>(`${this.namespace}/entities/${id}`, body)
    return {
      ...data,
      data: new EntityResource(data.data, this.implementation_id)
    }
  }

  public async deleteEntity(id: number): Promise<void> {
    await this.client.delete<void>(`${this.namespace}/entities/${id}`)
    return
  }

  public async createQuestion(body: QuestionCreateRequest): Promise<DetailResponse<QuestionResource>> {
    const { data } = await this.client.post<DetailResponse<IQuestionResource>>(`${this.namespace}/questions`, body)
    return {
      ...data,
      data: new QuestionResource(data.data, this.implementation_id)
    }
  }

  public async patchQuestion(question_id: number, body: QuestionPatchRequest): Promise<DetailResponse<QuestionResource>> {
    const { data } = await this.client.patch<DetailResponse<IQuestionResource>>(`${this.namespace}/questions/${question_id}`, body)
    return {
      ...data,
      data: new QuestionResource(data.data, this.implementation_id)
    }
  }

  public async deleteQuestion(question_id: number): Promise<void> {
    await this.client.delete<void>(`${this.namespace}/questions/${question_id}`)
    return
  }

  public async enableQuestions(): Promise<DetailResponse<ImplementationResource>> {
    const { data } = await this.client.post<DetailResponse<IImplementationResource>>(`${this.namespace}/questions/enable`)
    return {
      ...data,
      data: new ImplementationResource(data.data)
    }
  }

  public async disableQuestions(): Promise<DetailResponse<ImplementationResource>> {
    const { data } = await this.client.post<DetailResponse<IImplementationResource>>(`${this.namespace}/questions/disable`)
    return {
      ...data,
      data: new ImplementationResource(data.data)
    }
  }

}
