import { canPerformAction } from '@/helpers/canPerformAction'
import { formatDate } from '@/helpers/formatDate'
import { IUserResource, UserResource } from '../users/UserResource'
import { EntityResource, IEntityResource } from './entities/EntityResource'
import { ImplementationPermissions } from './ImplementationResource'

export interface IImplementationCollectionResource {
  id: number
  name: string
  entities_count: number
  owner: IUserResource
  created_at: string
  status: string
  entities: IEntityResource[]
  permissions: ImplementationPermissions[]
}

export class ImplementationCollectionResource {
  public id: number
  public name: string
  public entities_count: number
  public owner: UserResource
  public created_at: string
  public status: string
  public entities: EntityResource[]
  public permissions: ImplementationPermissions[]

  constructor(implementation: IImplementationCollectionResource) {
    this.id = implementation.id
    this.name = implementation.name
    this.entities_count = implementation.entities_count
    this.owner = new UserResource(implementation.owner)
    this.created_at = formatDate(new Date(implementation.created_at))
    this.status = implementation.status
    this.entities = implementation.entities?.map((entity) => new EntityResource(entity, implementation.id)) ?? []
    this.permissions = implementation.permissions
  }

  public get initiator (): string {
    return this.owner.name
  }

  public canPerformAction(key: ImplementationPermissions): boolean {
    return canPerformAction<ImplementationPermissions>(this.permissions, key)
  }

}
