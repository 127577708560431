import { API } from '@/api'
import { canPerformAction } from '@/helpers/canPerformAction'
import { QuestionAnswerRequest } from '@/requests/implementations/questions/QuestionAnswerRequest'
import { QuestionPatchRequest } from '@/requests/implementations/questions/QuestionPatchRequest'
import { ImplementationService } from '@/services/implementation'
import { AnswerResource, IAnswerResource } from './AnswerResource'

export interface IQuestionResource {
  id: number
  question: string
  status: string
  answer?: IAnswerResource
  answered?: boolean
  created_at: string
  updated_at: string
  permissions: QuestionPermissions[]
}

type QuestionPermissions = 'can_edit_question' | 'can_delete_question' | 'can_answer_question'


const config = {
  headers: {
      'content-type': 'multipart/form-data'
  }
}
export class QuestionResource {
  public id: number
  public question: string
  public status: string
  public answer?: AnswerResource
  public answered: boolean
  public created_at: string
  public updated_at: string
  public permissions: QuestionPermissions[]

  public implementation_id: number

  public implementationService: ImplementationService

  constructor(question: IQuestionResource, implementation_id: number) {
    this.id = question.id
    this.question = question.question
    this.status = question.status
    this.created_at = question.created_at
    this.updated_at = question.updated_at
    this.answered = question.answered ?? !!question.answer
    this.answer = question.answer && question.answer.relevance !== null ? new AnswerResource(question.answer) : undefined
    this.permissions = question.permissions

    this.implementation_id = implementation_id

    this.implementationService = new ImplementationService({ implementation_id })
  }

  public canPerformAction(key: QuestionPermissions): boolean {
    return canPerformAction<QuestionPermissions>(this.permissions, key)
  }

  public async delete(): Promise<void> {
    await this.implementationService.deleteQuestion(this.id)
    return
  }

  public async patch(body: QuestionPatchRequest): Promise<QuestionResource> {
    const { data } = await this.implementationService.patchQuestion(this.id, body)
    this.question = data.question
    return this
  }

  public async answerQuestion({ entity_id, proposal_id, body }: { entity_id: number, proposal_id: number, body: QuestionAnswerRequest }): Promise<QuestionResource> {
    const { data } = await API.post<DetailResponse<IQuestionResource>>(`implementations/${this.implementation_id}/entities/${entity_id}/proposals/${proposal_id}/questions/${this.id}`, body.requestBody, config)
    this.answer = data.data.answer ? new AnswerResource(data.data.answer) : undefined
    this.answered = data.data.answered ?? !!data.data.answer
    return this
  }

}
