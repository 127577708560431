import { IStatusResource } from '@/models/status/StatusResource'
import { InformationCreateRequest } from '@/requests/implementations/information/InformationCreateRequest'
import { ImplementationProposal } from '@/services/implementationProposal'
import { InformationResource } from '../information/InformationResource'
export interface IImplementationProposalResource {
  id:	number
  project_id:	number
  process_id:	number
  proposal_name: string
  description: string
  status?: IStatusResource
  proposal_value_overview: string
  inherited?: boolean
}

export class ImplementationProposalResource {
  public id:	number
  public project_id:	number
  public process_id:	number
  public proposal_name: string
  public description: string
  public proposal_value_overview: string

  public implementation_id: number

  public service: ImplementationProposal

  constructor({ proposal, implementation_id }: { proposal: IImplementationProposalResource, implementation_id: number }) {
    this.id = proposal.id
    this.project_id = proposal.project_id
    this.process_id = proposal.process_id
    this.proposal_name = proposal.proposal_name
    this.description = proposal.description
    this.proposal_value_overview = proposal.proposal_value_overview

    this.implementation_id = implementation_id

    this.service = new ImplementationProposal({ implementation_id: this.implementation_id, proposal_id: this.id })
  }

  public async getInformation(): Promise<IndexResponse<InformationResource>> {
    return await this.service.getInformation()
  }

  public async createInformation(body: InformationCreateRequest): Promise<DetailResponse<InformationResource>> {
    return await this.service.createInformation(body)
  }

  public async deleteInformation(information_id: number): Promise<void> {
    return await this.service.deleteInformation(information_id)
  }
}
