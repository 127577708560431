import { EntityCollectionResource } from '@/models/implementations/entities/EntityCollectionResource'

export class EntityUpdateRequest {
  public name: string = ''
  public lead_id: number | null = null

  constructor(entity: EntityCollectionResource) {
    this.name = entity.name
    this.lead_id = entity.lead.id
  }

  public get isValid() {
    return !!this.name && !!this.lead_id
  }
}
