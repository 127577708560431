import { createSocket }                                                  from '@/api'
import { ChatController }                                                from '@/controllers/chat/ChatController'
import { NotificationsController }                                       from '@/controllers/notifications/NotificationsController'
import { canPerformAction }                                              from '@/helpers/canPerformAction'
import { AttachUsersRequest }                                            from '@/requests/implementations/AttachUsersRequest'
import { EntityCreateRequest }                                           from '@/requests/implementations/entities/EntityCreateRequest'
import { QuestionCreateRequest }                                         from '@/requests/implementations/questions/QuestionCreateRequest'
import { ImplementationService }                                         from '@/services/implementation'
import { CommentResource, isCommentResource }                            from '../comments/CommentResource'
import { IUserResource, UserResource }                                   from '../users/UserResource'
import { EntityCollectionResource, IEntityCollectionResource }           from './entities/EntityCollectionResource'
import { EntityResource }                                                from './entities/EntityResource'
import { IImplementationProcessResource, ImplementationProcessResource } from './processes/ImplementationProcessResource'
import { IQuestionResource, QuestionResource }                           from './questions/QuestionResource'
import { ImplementationsService }                                        from '@/services/implementations'
import { Socket }                                                        from 'socket.io-client'

export type ImplementationPermissions =
    'can_change_entity'
    | 'can_create_entity'
    | 'can_add_users_to_implementation'
    | 'can_delete_lead'
    | 'can_see_all_entities'
    | 'can_add_question'
    | 'can_add_information'

export interface IImplementationResource {
  id: number
  name: string
  pdf_link: string
  entities_count: number
  team_ids: number[]
  entities: IEntityCollectionResource[]
  owner: IUserResource
  created_at: string
  status: string
  permissions: ImplementationPermissions[]
  processes: IImplementationProcessResource[]
  questions: IQuestionResource[]
  channel: string
  chat_channel: string
  stats: {
    entities_count: number,
    answered_count: number
  }
}

export class ImplementationResource {
  public id: number
  public name: string
  public entities_count: number
  public owner: UserResource
  public created_at: Date
  public status: string
  public pdf_link: string
  public team_ids: number[]
  public permissions: ImplementationPermissions[]
  public entities: EntityCollectionResource[]
  public questions: QuestionResource[]
  public processes: ImplementationProcessResource[]
  public stats: {
    entities_count: number,
    answered_count: number
  }

  public channel: string
  public chat_channel: string

  public notifications: NotificationsController
  public activities: NotificationsController
  public chat: ChatController

  private chatSocket?: Socket
  private service: ImplementationService
  private implementationsService: ImplementationsService

  constructor(implementation: IImplementationResource) {
    this.id = implementation.id
    this.name = implementation.name
    this.entities_count = implementation.entities_count
    this.team_ids = implementation.team_ids
    this.pdf_link = implementation.pdf_link
    this.owner = new UserResource(implementation.owner)
    this.created_at = new Date(implementation.created_at)
    this.status = implementation.status
    this.permissions = implementation.permissions
    this.stats = implementation.stats

    this.entities = implementation.entities?.map((entity) => new EntityCollectionResource(entity, implementation.id)) ?? []
    this.questions = implementation.questions?.map((question) => new QuestionResource(question, this.id)) ?? []
    this.processes = implementation.processes?.map((process) => new ImplementationProcessResource({
      process,
      implementation_id: implementation.id
    })) ?? []

    this.channel = implementation.channel
    this.chat_channel = implementation.chat_channel

    this.service = new ImplementationService({ implementation_id: implementation.id })
    this.implementationsService = new ImplementationsService()

    this.notifications = new NotificationsController({ service: this.service, namespace: 'notifications' })
    this.activities = new NotificationsController({ service: this.service, namespace: 'activities' })
    this.chat = new ChatController({ id: this.id, commentableType: 'implementation_chat', service: this.service })

    this.openSocket()
  }

  public canPerformAction(key: ImplementationPermissions): boolean {
    return canPerformAction<ImplementationPermissions>(this.permissions, key)
  }

  public async refresh(): Promise<ImplementationResource> {
    const { data } = await this.implementationsService.getById(this.id)
    this.setData(data)

    return this
  }

  // USERS
  public async getUsers(): Promise<IndexResponse<UserResource>> {
    return await this.service.getUsers()
  }

  public async getAvailableUsers(): Promise<IndexResponse<UserResource>> {
    return await this.service.getAvailableUsers()
  }

  public async getAvailableEntityUsers(): Promise<IndexResponse<UserResource>> {
    return await this.service.getAvailableEntityUsers()
  }

  public async attachUsers(body: AttachUsersRequest): Promise<void> {
    return await this.service.attachUsers(body)
  }

  public async deleteUser(user: UserResource): Promise<IndexResponse<UserResource>> {
    return await this.service.deleteUser(user.id)
  }

  // ENTITIES
  public async createEntity(body: EntityCreateRequest): Promise<DetailResponse<EntityResource>> {
    return await this.service.createEntity(body)
  }

  public async getEntities(params: IndexParameters): Promise<IndexResponse<EntityCollectionResource>> {
    return await this.service.getEntities(params)
  }

  public async getEntityById(id: number): Promise<DetailResponse<EntityResource>> {
    return await this.service.getEntityById(id)
  }

  public async deleteEntity(id: number): Promise<void> {
    return await this.service.deleteEntity(id)
  }

  // QUESTIONS
  public async createQuestion(body: QuestionCreateRequest): Promise<DetailResponse<QuestionResource>> {
    const data = await this.service.createQuestion(body)
    this.questions.push(data.data)
    return data
  }

  public async deleteQuestion(question: QuestionResource): Promise<ImplementationResource> {
    this.questions = this.questions.filter(({ id }) => id !== question.id)
    return this
  }

  public async enableQuestions(): Promise<ImplementationResource> {
    const { data } = await this.service.enableQuestions()
    this.status = data.status
    this.questions = data.questions?.map((question) => new QuestionResource(question, this.id)) ?? []
    this.permissions = data.permissions
    return this
  }

  public async disableQuestions(): Promise<ImplementationResource> {
    const { data } = await this.service.disableQuestions()
    this.status = data.status
    this.questions = data.questions?.map((question) => new QuestionResource(question, this.id)) ?? []
    this.permissions = data.permissions
    return this
  }

  public async getAllUsers(): Promise<IndexResponse<UserResource>> {
    return await this.service.getAllUsers()
  }

  public destroy(): void {
    this.chatSocket?.off('event', this.handleEvent)
  }

  private handleEvent({ event, message }: { event: string, message: unknown }): void {
    if (isCommentResource(message)) {
      this.chat.addMessage(new CommentResource(message))
    }
  }

  private setData(implementation: ImplementationResource): void {
    this.status = implementation.status
    this.permissions = implementation.permissions
    this.stats = implementation.stats
    this.name = implementation.name
    this.entities_count = implementation.entities_count
    this.team_ids = implementation.team_ids

    this.entities = implementation.entities?.map((entity) => new EntityCollectionResource(entity, implementation.id)) ?? []
    this.questions = implementation.questions?.map((question) => new QuestionResource(question, this.id)) ?? []
  }

  private async openSocket(): Promise<void> {
    this.chatSocket = await createSocket(this.chat_channel)
    this.chatSocket?.on('event', this.handleEvent)
  }
}
