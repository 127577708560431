import { IUserResource, UserResource } from '@/models/users/UserResource'

export interface IAnswerResource {
  message: string
  relevance: boolean
  proof?: Array<{ name: string, url: string }>
  answered_by: IUserResource
}

export class AnswerResource {
  public message: string
  public relevance: boolean
  public proof?: Array<{ name: string, url: string }>
  public answered_by: UserResource

  constructor(answer: IAnswerResource) {
    this.message = answer.message
    this.relevance = answer.relevance
    this.proof = answer.proof
    this.answered_by = new UserResource(answer.answered_by)
  }
}
