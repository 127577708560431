import { CommentsController } from '@/controllers/comments/CommentsController'
import { ImplementationProposal } from '@/services/implementationProposal'
import { IInformationResource, InformationResource } from '../information/InformationResource'
import { QuestionResource } from '../questions/QuestionResource'
import { IImplementationProposalResource, ImplementationProposalResource } from './ImplementationProposalResource'

export interface IImplementationEntityProposalResource extends IImplementationProposalResource {
  channel: string
  stats: {
    questions_count: number
    answered_count: number
  }
  information?: IInformationResource[]
}

export const isImplementationEntityProposalResource = (item: ImplementationProposalResource): item is ImplementationEntityProposalResource => {
  return 'stats' in item
}

export class ImplementationEntityProposalResource extends ImplementationProposalResource {

  public channel: string
  public stats: {
    questions_count: number
    answered_count: number
  }
  public information: InformationResource[]
  public entity_id: number

  public comments: CommentsController
  // public socket: Socket
  public service: ImplementationProposal

  constructor({ proposal, implementation_id, entity_id }: { proposal: IImplementationEntityProposalResource, implementation_id: number, entity_id: number }) {
    super({ proposal, implementation_id })

    this.channel = proposal.channel
    this.stats = proposal.stats
    this.information = proposal.information ? proposal.information.map((info) => new InformationResource(info, this.implementation_id, this.id)) : []

    this.entity_id = entity_id

    this.service = new ImplementationProposal({ implementation_id: this.implementation_id, entity_id, proposal_id: this.id })
    this.comments = new CommentsController({ id: proposal.id, service: this.service, commentableType: 'implementation_proposal', entity_id })

    // this.socket = createSocket(proposal.channel)
    // this.socket.on('event', (e: EventResponse) => this.handleEvent(e))
  }

  public destroy(): void {
    // this.socket.off('event', (e: EventResponse) => this.handleEvent(e))
  }

  public async getQuestions(): Promise<IndexResponse<QuestionResource>> {
    return await this.service.getQuestions()
  }

//   private handleEvent(event: EventResponse): void {
//     switch (event) {
//
//     }
//   }


}
