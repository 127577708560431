




























import pdfjsLib from 'pdfjs-dist/webpack'
import { Component, Vue, Prop } from 'vue-property-decorator'
import SmallLoader from '@/components/loaders/SmallLoader.vue'

import { ImplementationResource } from '@/models/implementations/ImplementationResource'
import { stateModule } from '@/store'

@Component({
  components: {
    SmallLoader
  }
})
export default class ImplementationDetailDocument extends Vue {

  @Prop()
  private implementation!: ImplementationResource

  private viewer: any = null
  private isRenderingDocument: boolean = false

  private mounted(): void {
    this.openPdfViewer()
  }

  private async openPdfViewer(): Promise<void> {
    if (!this.implementation.pdf_link) return
    try {
      const viewerlib = require('pdfjs-dist/web/pdf_viewer')

      this.viewer = new viewerlib.PDFViewer({
        container: this.$refs.article as HTMLElement,
      })

      this.isRenderingDocument = true
      const doc = await pdfjsLib.getDocument({
        url: this.implementation.pdf_link,
        withCredentials: true,
      }).promise

      this.viewer.setDocument(doc)
    } catch {
      stateModule.setNotification({
        message: 'Something went wrong while rendering this document.',
        type: 'error'
      })
    } finally {
      this.isRenderingDocument = false
    }

  }

}
