import { canPerformAction } from '@/helpers/canPerformAction'
import { formatFullDate } from '@/helpers/formatDate'
import { IUserResource, UserResource } from '@/models/users/UserResource'
import { InformationPatchRequest } from '@/requests/implementations/information/InformationPatchRequest'
import { ImplementationProposal } from '@/services/implementationProposal'

type InformationPermissions = 'can_edit_information' | 'can_delete_information'

export interface IInformationResource {
  id: number
  message: string
  files?: Array<{ name: string, url: string }>
  created_by?: IUserResource
  permissions?: InformationPermissions[]
  created_at?: Date | string
}

export class InformationResource {
  public id: number
  public message: string
  public files?: Array<{ name: string, url: string }>
  public created_by?: UserResource
  public permissions: InformationPermissions[]
  public created_at: Date | string

  public proposal_id: number
  public implementation_id: number

  private service: ImplementationProposal

  constructor(information: IInformationResource, implementation_id: number, proposal_id: number) {
    this.id = information.id
    this.message = information.message
    this.files = information.files
    this.created_by = information.created_by ? new UserResource(information.created_by) : undefined
    this.permissions = information.permissions ?? []
    this.created_at = information.created_at ? new Date(information.created_at) : new Date()

    this.proposal_id = proposal_id
    this.implementation_id = implementation_id

    this.service = new ImplementationProposal({ implementation_id: this.implementation_id, proposal_id: this.proposal_id })
  }

  public canPerformAction(key: InformationPermissions): boolean {
    return canPerformAction<InformationPermissions>(this.permissions, key)
  }

  public get formattedCreatedAt(): string {
    if (typeof this.created_at !== 'string') {
      return formatFullDate(this.created_at)
    }

    return this.created_at
  }

  public async update(body: InformationPatchRequest): Promise<InformationResource> {
    const { data } = await this.service.updateInformation(this.id, body)
    this.message = data.message
    this.files = data.files
    return this
  }

  public async delete(): Promise<InformationResource> {
    await this.service.deleteInformation(this.id)
    return this
  }
}

