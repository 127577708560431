import { AxiosPromise } from 'axios'
import { BaseService } from '@/services/base'

import { ProjectResource } from '@/models/projects/ProjectResource'

import { IUserResource, UserResource } from '@/models/users/UserResource'
import { AttachUsersRequest } from '@/requests/implementations/AttachUsersRequest'

export class EntityService extends BaseService {

  public entity_id: number
  public implementation_id: number

  constructor({ implementation_id, entity_id }: { implementation_id: number, entity_id: number }) {
    super(`implementations/${implementation_id}/entities/${entity_id}`)
    this.entity_id = entity_id
    this.implementation_id = implementation_id
  }

  public async get(): Promise<DetailResponse<ProjectResource>> {
    const { data } = await this.client.get(`${this.namespace}`)
    data.data = new ProjectResource(data.data)
    return data
  }

  public delete(): AxiosPromise<void> {
    return this.client.delete<void>(`${this.namespace}`)
  }

  public async getAllUsers(): Promise<IndexResponse<UserResource>> {
    const { data } = await this.client.get<IndexResponse<IUserResource>>(`${this.namespace}/all-users`)
    return {
      ...data,
      data: data.data.map((user) => new UserResource(user))
    }
  }

  public async getUsers(): Promise<IndexResponse<UserResource>> {
    const { data } = await this.client.get<IndexResponse<IUserResource>>(`${this.namespace}/users`)
    return {
      ...data,
      data: data.data.map((user) => new UserResource(user))
    }
  }

  public async getAvailableUsers(): Promise<IndexResponse<UserResource>> {
    const { data } = await this.client.get<IndexResponse<IUserResource>>(`${this.namespace}/availableUsers`)
    return {
      ...data,
      data: data.data.map((user) => new UserResource(user))
    }
  }

  public async attachUsers(body: AttachUsersRequest): Promise<void> {
    await this.client.post<void>(`${this.namespace}/users`, body)
    return
  }

  public async deleteUser(userId: number): Promise<IndexResponse<UserResource>> {
    const { data } = await this.client.delete<IndexResponse<IUserResource>>(`${this.namespace}/users/${userId}`)
    return {
      ...data,
      data: data.data.map((user) => new UserResource(user))
    }
  }

}
