import { BaseService } from '@/services/base'

import { ImplementationGetRequest } from '@/requests/implementations/ImplementationGetRequest'

import { ImplementationCreateRequest } from '@/requests/implementations/ImplementationCreateRequest'
import { IImplementationResource, ImplementationResource } from '@/models/implementations/ImplementationResource'
import { IImplementationCollectionResource, ImplementationCollectionResource } from '@/models/implementations/ImplementationCollectionResource'

export class ImplementationsService extends BaseService {

  constructor() {
    super(`implementations`)
  }

  /**
   * Get all implementations
   */
  public async get(params: ImplementationGetRequest): Promise<IndexResponse<ImplementationCollectionResource>> {
    const { data } = await this.client.get<IndexResponse<IImplementationCollectionResource>>(`${this.namespace}`, { params })
    return {
      ...data,
      data: data.data.map((implementation) => new ImplementationCollectionResource(implementation))
    }
  }

  public async create(body: ImplementationCreateRequest): Promise<DetailResponse<ImplementationResource>> {
    const { data } = await this.client.post<DetailResponse<IImplementationResource>>(`${this.namespace}`, body)
    return {
      ...data,
      data: new ImplementationResource(data.data)
    }
  }

  public async getById(implementation_id: number): Promise<DetailResponse<ImplementationResource>> {
    const { data } = await this.client.get<DetailResponse<IImplementationResource>>(`${this.namespace}/${implementation_id}`)
    return {
      ...data,
      data: new ImplementationResource(data.data)
    }
  }
}
