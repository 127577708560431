import { IImplementationProposalResource, ImplementationProposalResource } from '../proposal/ImplementationProposalResource'

export interface IImplementationProcessResource {
  id: number
  project_id:	number
  process_name: string
  singular_name: string
  description: string
  proposals:	IImplementationProposalResource[]
}

export class ImplementationProcessResource {
  public id: number
  public project_id:	number
  public process_name: string
  public singular_name: string
  public description: string
  public proposals:	ImplementationProposalResource[]

  public implementation_id: number

  constructor({ process, implementation_id }: { process: IImplementationProcessResource, implementation_id: number }) {
    this.id = process.id
    this.project_id = process.project_id
    this.process_name = process.process_name
    this.singular_name = process.singular_name
    this.description = process.description

    this.proposals = process?.proposals.map((proposal) => new ImplementationProposalResource({ proposal, implementation_id })) ?? []

    this.implementation_id = implementation_id
  }
}
