import { IUserResource, UserResource } from '@/models/users/UserResource'
import { EntityUpdateRequest } from '@/requests/implementations/entities/EntityUpdateRequest'
import { ImplementationService } from '@/services/implementation'

export interface IEntityCollectionResource {
  id: number
  name: string
  lead: IUserResource
  belongs_to: boolean
  stats: {
    questions_count: number
    answered_count: number
  }
}

export class EntityCollectionResource {
  public id: number
  public name: string
  public lead: UserResource
  public belongs_to: boolean
  public stats: {
    questions_count: number
    answered_count: number
  }
  public implementation_id: number

  public updateForm: EntityUpdateRequest

  protected implementationService: ImplementationService

  constructor(entity: IEntityCollectionResource, implementation_id: number) {
    this.id = entity.id
    this.name = entity.name
    this.lead = new UserResource(entity.lead)
    this.belongs_to = entity.belongs_to
    this.stats = entity.stats
    this.implementation_id = implementation_id

    this.updateForm = new EntityUpdateRequest(this)

    this.implementationService = new ImplementationService({ implementation_id })
  }

  public get implementation_lead(): string {
    return this.lead.name
  }

  public async update(): Promise<EntityCollectionResource> {
    const { data } = await this.implementationService.updateEntity(this.id, this.updateForm)
    this.name = data.name
    this.lead = data.lead
    return this
  }


}
