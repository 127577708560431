import { CommentResource } from '@/models/comments/CommentResource'
import { IInformationResource, InformationResource } from '@/models/implementations/information/InformationResource'
import { IQuestionResource, QuestionResource } from '@/models/implementations/questions/QuestionResource'
import { InformationCreateRequest } from '@/requests/implementations/information/InformationCreateRequest'
import { InformationPatchRequest } from '@/requests/implementations/information/InformationPatchRequest'
import { BaseService } from '@/services/base'

export class ImplementationProposal extends BaseService {

  public implementation_id: number
  public entity_id?: number
  public proposal_id: number

  constructor({ implementation_id, entity_id, proposal_id }: { implementation_id: number, entity_id?: number, proposal_id: number }) {
    super(`implementations/${implementation_id}/proposals/${proposal_id}`)
    this.implementation_id = implementation_id
    this.entity_id = entity_id
    this.proposal_id = proposal_id
  }

  public async getInformation(): Promise<IndexResponse<InformationResource>> {
    const { data } = await this.client.get<IndexResponse<IInformationResource>>(`${this.namespace}/information`)
    return {
      ...data,
      data: data.data.map((info) => new InformationResource(info, this.implementation_id, this.proposal_id))
    }
  }

  public async createInformation(body: InformationCreateRequest): Promise<DetailResponse<InformationResource>> {
    const { data } = await this.client.post<DetailResponse<IInformationResource>>(`${this.namespace}/information`, body.requestBody)
    return {
      ...data,
      data: new InformationResource(data.data, this.implementation_id, this.proposal_id)
    }
  }

  public async updateInformation(information_id: number, body: InformationPatchRequest): Promise<DetailResponse<InformationResource>> {
    const { data } = await this.client.post<DetailResponse<IInformationResource>>(`${this.namespace}/information/${information_id}`, body.requestBody)
    return {
      ...data,
      data: new InformationResource(data.data, this.implementation_id, this.proposal_id)
    }
  }

  public async deleteInformation(information_id: number): Promise<void> {
    await this.client.delete<DetailResponse<IInformationResource>>(`${this.namespace}/information/${information_id}`)
    return
  }

  public async getComments(): Promise<IndexResponse<CommentResource>> {
    if (!this.entity_id) throw new Error('No entity id')

    const { data } = await this.client.get(`implementations/${this.implementation_id}/entities/${this.entity_id}/proposals/${this.proposal_id}/comments`)
    data.data = data.data.map((comment: CommentResource) => new CommentResource(comment))
    return data
  }

  public async getQuestions(): Promise<IndexResponse<QuestionResource>> {
    if (!this.entity_id) throw new Error('No entity id')
    const { data } = await this.client.get<IndexResponse<IQuestionResource>>(`implementations/${this.implementation_id}/entities/${this.entity_id}/proposals/${this.proposal_id}/questions`)
    return {
      ...data,
      data: data.data.map((question) => new QuestionResource(question, this.implementation_id))
    }
  }

}
