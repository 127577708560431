import { canPerformAction } from '@/helpers/canPerformAction'
import { UserResource } from '@/models/users/UserResource'
import { AttachUsersRequest } from '@/requests/implementations/AttachUsersRequest'
import { EntityService } from '@/services/entity'
import { IQuestionResource, QuestionResource } from '../questions/QuestionResource'
import { IImplementationEntityProcessResource, ImplementationEntityProcessResource } from '../processes/ImplementationEntityProcessResource'
import { IEntityCollectionResource, EntityCollectionResource } from './EntityCollectionResource'

type EntityPermissions = 'can_add_users_to_entity' | 'can_delete_user_from_entity'

export interface IEntityResource extends IEntityCollectionResource {
  team_ids: number[]
  questions: IQuestionResource[]
  permissions: EntityPermissions[]
  processes: IImplementationEntityProcessResource[]
}

export class EntityResource extends EntityCollectionResource {
  public team_ids: number[]
  public questions: QuestionResource[]
  public permissions: EntityPermissions[]
  public processes: ImplementationEntityProcessResource[]

  public implementation_id: number

  private service: EntityService

  constructor(entity: IEntityResource, implementation_id: number) {
    super(entity, implementation_id)
    this.team_ids = entity.team_ids
    this.questions = entity.questions ? entity.questions.map((question) => new QuestionResource(question, implementation_id)) : []
    this.processes = entity.processes?.map((process) => new ImplementationEntityProcessResource({ process, implementation_id, entity_id: this.id })) ?? []
    this.permissions = entity.permissions
    this.implementation_id = implementation_id

    this.service = new EntityService({ entity_id: entity.id, implementation_id })
  }

  public canPerformAction(key: EntityPermissions): boolean {
    return canPerformAction<EntityPermissions>(this.permissions, key)
  }

  public async refresh(): Promise<EntityResource> {
    const { data } = await this.implementationService.getEntityById(this.id)
    this.setData(data)

    return this
  }

  public async getAllUsers(): Promise<IndexResponse<UserResource>> {
    return await this.service.getAllUsers()
  }

  public async getUsers(): Promise<IndexResponse<UserResource>> {
    return await this.service.getUsers()
  }

  public async getAvailableUsers(): Promise<IndexResponse<UserResource>> {
    return await this.service.getAvailableUsers()
  }

  public async attachUsers(body: AttachUsersRequest): Promise<void> {
    return await this.service.attachUsers(body)
  }

  public async deleteUser(user: UserResource): Promise<IndexResponse<UserResource>> {
    return await this.service.deleteUser(user.id)
  }

  private setData(entity: EntityResource): void {
    this.team_ids = entity.team_ids
    this.questions = entity.questions ? entity.questions.map((question) => new QuestionResource(question, entity.implementation_id)) : []
    this.processes = entity.processes?.map((process) => new ImplementationEntityProcessResource({ process, implementation_id: entity.implementation_id, entity_id: this.id })) ?? []
    this.permissions = entity.permissions
  }

}
